import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { setCurrentCurrency } from 'store/slices/currencySlice'
import { fetchUserBalance } from 'store/slices/userDataSlice'
import Credit from './Credit'
import { useEffect } from 'react'

const CreditContainer: React.FC = () => {
    const dispatch = useAppDispatch()

    const realBalance = useAppSelector((state) => state.user.currentBalanceData.real.value)
    const funBalance = useAppSelector((state) => state.user.currentBalanceData.fun.value)
    const { configuredCurrencies, currentCurrency, funCurrencyConfig } = useAppSelector((state) => state.currencyState)

    useEffect(() => {
        dispatch(fetchUserBalance())
    }, [dispatch])

    const handleAsyncAction = () => {
        dispatch(fetchUserBalance())
    }

    const handleChangeCurrency = (currency: string) => {
        dispatch(setCurrentCurrency(currency))
    }

    return (
        <>
            {currentCurrency && funCurrencyConfig && (
                <Credit
                    realBalance={realBalance}
                    funBalance={funBalance}
                    handleChangeCurrency={handleChangeCurrency}
                    handleAsyncAction={handleAsyncAction}
                    configuredCurrencies={configuredCurrencies}
                    currentCurrency={currentCurrency}
                    funCurrencyConfig={funCurrencyConfig}
                />
            )}
        </>
    )
}

export default CreditContainer
