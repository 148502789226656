import { Currency } from './common'
import { Status } from './service'

export interface Data {
    url: string
    status: Status
}

export interface OpenGameResponse {
    data: Data
}

export interface LegacyBackendGameURLParams {
    gameUid: string
    mode: string
    currency: string
    language: string
    version: string
    gameBackend: string
    env: string
    site: string
}

export interface OSIBackendOpenGameURLParams {
    gameUid: string
    mode: GameMode
    currency: string
    integration: string
    language: string
    mobile: boolean
    amount: number
    exitUrl: string
    version: string
    env: string
}

export interface DataGames {
    games: Game[]
    status: Status
}

export interface GamesInfoResponse {
    data: DataGames
}

export interface EnvLanguage {
    id: string
    description: string
}

interface EnvLanguageData {
    languages: EnvLanguage[]
    status: Status
}

export interface EnvironmentLanguageInfoResponse {
    data: EnvLanguageData
}

export interface ConfiguredCurrencyData {
    id: string
    valuePrecision: number
    currencyConfig: string
}

interface ConfiguredCurrenciesData {
    currencies: ConfiguredCurrencyData[]
    status: Status
}

export interface ConfiguredCurrenciesResponse {
    data: ConfiguredCurrenciesData
}

export enum GameMode {
    DEMO = 'DEMO',
    REAL = 'REAL',
    FUN = 'FUN',
}

export interface RunGameParameters {
    gameUid: string
    mode: GameMode
    version: string
    language: string
    currency: string
}

interface LegacyStatuses {
    LIGHT_WALLET_2_SITE: GameStatus
    LIGHT_WALLET_PORTUGAL_SITE: GameStatus
    LIGHT_WALLET_SPAIN_SITE: GameStatus
    LIVE: GameStatus
}

interface OSIStatuses {
    LIGHT_WALLET_SITE_OSI: GameStatus
    LIVE: GameStatus
}

interface GameStatusForIntegrationBackend {
    OSI: OSIStatuses
    LEGACY: LegacyStatuses
}

interface GameVersion {
    modified: string
    size: string
}

export interface GameVersions {
    [name: string]: GameVersion
}

export interface Game {
    gameStatusForIntegrationBackend: GameStatusForIntegrationBackend
    gameUID?: string
    gameName?: string
    gameClass: string
    gameIconUrl?: string
    languages?: string[]
    rtp?: number
    features?: string[]
    mathFileHash?: string
    mathFileHashType?: string
    mathFileName?: string
    currency?: Currency[]
    release?: Release
    versions?: GameVersions
    latestVersion?: string
}

export interface GameWithEnvironmentReleaseInfo extends Game {
    gameLiveReleaseInfo?: string
    gameDevReleaseInfo?: string
    liveAndDevHasEqualVersion?: boolean
}

export interface Release {
    timestamp: string
    tag: string
    commit: string
}

export type GameStatus =
    | 'OK'
    | 'GAME_NOT_CONFIGURED'
    | 'ASSETS_NOT_INSTALLED'
    | 'MATH_NOT_INSTALLED'
    | 'NOT_AVAILABLE'
    | 'DISABLED'

export type Environment = 'DEV' | 'STAGE' | 'CERT' | 'LIVE'
