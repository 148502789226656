import { ENVIRONMENT } from 'consts/constants'
import { BalanceMode, Page } from 'types/common'
import { Balance, Permission, PermissionTitle } from 'types/user'

export function extractUserBalance({
    userBalance,
    balanceMode,
}: {
    userBalance: Balance[]
    balanceMode: BalanceMode
}): number | undefined {
    return userBalance.find(({ mode }) => mode === balanceMode)?.value
}

const permissionsToEnvsMappingForMainPage: Partial<Record<PermissionTitle, string>> = {
    [PermissionTitle.DevGameAccess]: ENVIRONMENT.DEV,
    [PermissionTitle.StageGameAccess]: ENVIRONMENT.STAGE,
    [PermissionTitle.CertGameAccess]: ENVIRONMENT.CERT,
}

const permissionsToEnvsMappingForOtherPages: Partial<Record<PermissionTitle, string>> = {
    [PermissionTitle.DevGameAccess]: ENVIRONMENT.DEV,
    [PermissionTitle.StageGameAccess]: ENVIRONMENT.STAGE,
    [PermissionTitle.CertGameAccess]: ENVIRONMENT.CERT,
    [PermissionTitle.LiveGameAccess]: ENVIRONMENT.LIVE,
}

export function getAllowedEnvironments(permissions: Permission[], page: Page): string[] {
    const mapping = page === Page.Main ? permissionsToEnvsMappingForMainPage : permissionsToEnvsMappingForOtherPages

    return permissions
        .map((permission) => mapping[permission.title])
        .filter((environment): environment is string => environment !== undefined)
}

export function getInitEnvironment(allowedEnvironments: string[]): string {
    if (!allowedEnvironments.length) {
        return ''
    }
    return allowedEnvironments[0]
}

export function userHasAccessToEnvironment(permissions: Permission[], environment: string): boolean {
    return permissions
        .map((permission) => permissionsToEnvsMappingForOtherPages[permission.title])
        .includes(environment)
}

export function userHasPermission(permissions: Permission[], permissionTitle: PermissionTitle): boolean {
    return permissions.some((permission) => permission.title === permissionTitle)
}
