import { FC, useEffect, useState } from 'react'
import MissettledTransactionFeature from './MissettledTransactionFeature'
import UserService from 'services/UserService'

const MissettledTransactionFeatureContainer: FC = () => {
    const [isActive, setIsActive] = useState<boolean>(false)

    useEffect(() => {
        const fetchMissettledTransactionFeatureState = async () => {
            try {
                const response = await UserService.getMissettledTransactionFeatureState()
                setIsActive(response.data.missettledTransactionTestRequest)
            } catch (error) {
                const errorMessage = (error as Error).message
                console.error(errorMessage)
            }
        }

        fetchMissettledTransactionFeatureState()
    }, [])

    const onToggle = async () => {
        const newState = !isActive
        try {
            const response = await UserService.setMissettledTransactionFeatureState(newState)
            setIsActive(response.data.missettledTransactionTestRequest)
        } catch (error) {
            const errorMessage = (error as Error).message
            console.error(errorMessage)
        }
    }

    return <MissettledTransactionFeature isActive={isActive} onToggle={onToggle} />
}

export default MissettledTransactionFeatureContainer
