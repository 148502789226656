import React from 'react'
import styles from './userInfo.module.scss'
import CreditContainer from './Credit/CreditContainer'

interface UserInfoProps {
    userName?: string
}

const UserInfo: React.FC<UserInfoProps> = ({ userName }) => (
    <div className={styles.userInfo}>
        {userName && <div className={styles.userName}>{userName}</div>}
        <CreditContainer />
    </div>
)

export default UserInfo
