import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useFetchGames from 'hooks/useFetchGames'
import { fetchGameURL, clearCurrentGameError, setCurrentGameURL } from 'store/slices/currentGameSlice'
import { fetchUserBalance } from 'store/slices/userDataSlice'
import { Main } from './Main'
import { Iframe } from 'components/Iframe'
import { IntegrationBackend } from 'types/common'
import { DEFAULT_SELECTED_LICENSED_SITE, EXIT_URL, INTEGRATION, LANGUAGE } from 'consts/constants'
import { EnvLanguage, GameMode, RunGameParameters } from 'types/gameService'
import GameService from 'services/GameService'
import { Preloader } from 'components/Preloader/Preloader'
import CreditForm from 'components/CreditForm'
import { setGlobalLanguage, setSelectedLicensedSite } from 'store/slices/configUISlice'
import BackendService from 'services/BackendService'
import { LicensedSite } from 'types/backendService'
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar'
import { fetchConfiguredCurrencies } from 'store/slices/currencySlice'
import { ConfiguredCurrency } from 'types/configuredCurrency'
import { userHasPermission } from 'utils/userUtils'
import { PermissionTitle } from 'types/user'

export const MainContainer: React.FC = () => {
    const userInfo = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const URLGame = useAppSelector((state) => state.currentGame.URL)
    const { currentCurrency, funCurrencyConfig, configuredCurrencies } = useAppSelector((state) => state.currencyState)
    const currentPage = useAppSelector((state) => state.currentPage.currentPage)
    const { error } = useAppSelector((state) => state.currentGame)
    const {
        real: { value: realBalance },
        fun: { value: funBalance },
    } = useAppSelector((state) => state.user.currentBalanceData)
    const {
        integrationBackend,
        selectedLicensedSite,
        workingGamesSwitcherStates: { main: isOnlyWorkingGames },
        sortOrder,
        categorySort,
        isOnlyClassesA,
        service,
        environment,
        isFullScreenGame,
        globalLanguage,
    } = useAppSelector((state) => state.stateUI)

    const [searchedGameName, setSearchedGameName] = useState<string>('')
    const [isActiveIframe, setIsActiveIframe] = useState<boolean>(false)
    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)
    const [isLoadingGames, games, changeEnvironment] = useFetchGames(environment)
    const [isLoadingEnvLanguageInfo, setIsLoadingEnvLanguageInfo] = useState<boolean>(true)
    const [envLanguages, setEnvLanguages] = useState<EnvLanguage[]>([])
    const [isLoadingLicensedSites, setIsLoadingLicensedSites] = useState<boolean>(true)
    const [licensedSites, setLicensedSites] = useState<LicensedSite[]>([])
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [currentGameRealCurrency, setCurrentGameRealCurrency] = useState<ConfiguredCurrency | undefined>(
        currentCurrency
    )

    const iframeRef = useRef<HTMLIFrameElement>(null)
    const isMisSettledTransactionAllowed = userInfo.info
        ? userHasPermission(userInfo.info.role.permissions, PermissionTitle.MissettledTransaction)
        : false

    useEffect(() => {
        if (error) {
            setOpenAlert(true)
            setResponseMessage(error)
        }
    }, [error])

    useEffect(() => {
        dispatch(fetchConfiguredCurrencies(environment))
    }, [dispatch, environment])

    useEffect(() => {
        changeEnvironment(environment)
    }, [changeEnvironment, environment])

    useEffect(() => {
        if (isFullScreenGame && URLGame) {
            window.open(URLGame, '_blank')
            dispatch(setCurrentGameURL(''))
        } else if (URLGame) {
            setIsActiveIframe(true)
        }
    }, [dispatch, URLGame, isFullScreenGame])

    useEffect(() => {
        const fetchEnvironmentLanguages = async () => {
            setIsLoadingEnvLanguageInfo(true)

            try {
                const response = await GameService.getLanguagesInfo(environment)
                const { languages } = response.data
                setEnvLanguages(languages)
                const language = languages.some((language) => language.id === globalLanguage)
                    ? globalLanguage
                    : LANGUAGE.EN

                dispatch(setGlobalLanguage(language))
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoadingEnvLanguageInfo(false)
            }
        }

        fetchEnvironmentLanguages()
    }, [dispatch, environment, globalLanguage])

    useEffect(() => {
        const fetchLicensedSiteList = async () => {
            setIsLoadingLicensedSites(true)

            try {
                const response = await BackendService.getLicensedSiteList(environment, integrationBackend)
                const { sites } = response.data
                setLicensedSites(sites)

                const selectedSiteName = getSelectedSiteName(sites, selectedLicensedSite)
                dispatch(setSelectedLicensedSite(selectedSiteName))
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoadingLicensedSites(false)
            }
        }

        const getSelectedSiteName = (sites: LicensedSite[], licensedSite: string) => {
            if (sites.some((site) => site.name === licensedSite)) {
                return licensedSite
            }
            if (sites.some((site) => site.name === DEFAULT_SELECTED_LICENSED_SITE.NAME)) {
                return DEFAULT_SELECTED_LICENSED_SITE.NAME
            }
            return sites[0].name
        }

        fetchLicensedSiteList()
    }, [dispatch, environment, integrationBackend, selectedLicensedSite])

    const runGame = (runGameParameters: RunGameParameters): void => {
        setRealCurrencyForIframe(runGameParameters.currency)
        if (integrationBackend === IntegrationBackend.Legacy) {
            runLegacyIntegrationBackendGame(runGameParameters)
        } else {
            runOSIIntegrationBackendGame(runGameParameters)
        }
    }

    const runLegacyIntegrationBackendGame = ({ mode, gameUid, version, language, currency }: RunGameParameters) => {
        dispatch(
            fetchGameURL({
                integrationBackend: IntegrationBackend.Legacy,
                urlParams: {
                    gameUid,
                    mode,
                    currency,
                    language,
                    version,
                    gameBackend: service,
                    env: environment,
                    site: selectedLicensedSite,
                },
            })
        )
    }

    const runOSIIntegrationBackendGame = ({ mode, gameUid, version, language, currency }: RunGameParameters) => {
        const amount = mode === GameMode.REAL ? realBalance : funBalance

        dispatch(
            fetchGameURL({
                integrationBackend: IntegrationBackend.OSI,
                urlParams: {
                    gameUid,
                    mode,
                    currency,
                    integration: INTEGRATION.SEAMLESS,
                    language,
                    mobile: isMobile,
                    amount,
                    exitUrl: EXIT_URL,
                    version,
                    env: environment,
                },
            })
        )
    }

    const closeModalIframe = (status: boolean): void => {
        setIsActiveIframe(status)
        dispatch(setCurrentGameURL(''))
        dispatch(fetchUserBalance())
    }

    const handleClickOpenCreditForm = () => {
        setIsOpenCreditForm(true)
    }

    const sendMessageToIframe = (): void => {
        const message = { name: 'updateBalance' }
        const currentIframe = iframeRef.current

        if (currentIframe && currentIframe.contentWindow) {
            currentIframe.contentWindow.postMessage(message, '*')
        }
    }

    const handleAsyncAction = () => {
        dispatch(fetchUserBalance())
    }

    const handleAlertClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenAlert(false)
        clearCurrentGameError()
    }

    const setRealCurrencyForIframe = (currency: string) => {
        const configuredRealCurrency = configuredCurrencies.find(
            (configuredCurrency) => configuredCurrency.id === currency
        )

        setCurrentGameRealCurrency(configuredRealCurrency)
    }

    const isLoading = isLoadingGames && isLoadingEnvLanguageInfo && isLoadingLicensedSites

    return (
        <main className="main">
            {isLoading ? (
                <Preloader />
            ) : (
                <Main
                    searchedGameName={searchedGameName}
                    setSearchedGameName={setSearchedGameName}
                    games={games}
                    runGame={runGame}
                    service={service}
                    isOnlyWorkingGames={isOnlyWorkingGames}
                    isOnlyClassesA={isOnlyClassesA}
                    sortOrder={sortOrder}
                    categorySort={categorySort}
                    envLanguages={envLanguages}
                    licensedSites={licensedSites}
                    isMisSettledTransactionAllowed={isMisSettledTransactionAllowed}
                />
            )}
            {URLGame && isActiveIframe && (
                <div>
                    <Iframe
                        URLGame={URLGame}
                        closeModalIframe={closeModalIframe}
                        handleClickOpenCreditForm={handleClickOpenCreditForm}
                        currentPage={currentPage}
                        iframeRef={iframeRef}
                    />
                    {currentGameRealCurrency && funCurrencyConfig && (
                        <CreditForm
                            userAPIType="AuthenticatedUser"
                            isOpenCreditForm={isOpenCreditForm}
                            setIsOpenCreditForm={setIsOpenCreditForm}
                            realBalance={realBalance}
                            funBalance={funBalance}
                            realCurrency={currentGameRealCurrency}
                            funCurrencyConfig={funCurrencyConfig}
                            asyncAction={handleAsyncAction}
                            sendMessageToIframe={sendMessageToIframe}
                        />
                    )}
                </div>
            )}
            <CustomSnackbar open={openAlert} onClose={handleAlertClose} severity={'error'} message={responseMessage} />
        </main>
    )
}
