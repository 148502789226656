import UserInfo from './UserInfo'
import { useAppSelector } from 'hooks/useRedux'

const UserInfoContainer: React.FC = () => {
    const userName = useAppSelector((state) => state.user.info?.nickname)

    return <UserInfo userName={userName} />
}

export default UserInfoContainer
