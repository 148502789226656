import CreditForm from 'components/CreditForm'
import React, { useState } from 'react'
import { Button } from '@mui/material'
import styles from '../userInfo.module.scss'
import CreditReal from '../CreditReal/CreditReal'
import { CurrencyConfig, ConfiguredCurrency } from 'types/configuredCurrency'
import { formatBalance } from 'utils/currencyUtils'

interface CreditProps {
    realBalance: number
    funBalance: number
    handleChangeCurrency: (currency: string) => void
    handleAsyncAction: () => void
    configuredCurrencies: ConfiguredCurrency[]
    currentCurrency: ConfiguredCurrency
    funCurrencyConfig: CurrencyConfig
}

const Credit: React.FC<CreditProps> = ({
    realBalance,
    funBalance,
    handleChangeCurrency,
    handleAsyncAction,
    configuredCurrencies,
    currentCurrency,
    funCurrencyConfig,
}) => {
    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)

    const handleClickOpenCreditForm = () => {
        setIsOpenCreditForm(true)
    }

    return (
        <>
            <CreditReal
                handleClickOpenCreditForm={handleClickOpenCreditForm}
                handleChangeCurrency={handleChangeCurrency}
                realBalance={realBalance}
                configuredCurrencies={configuredCurrencies}
                currentCurrency={currentCurrency}
            />
            <CreditForm
                userAPIType="AuthenticatedUser"
                isOpenCreditForm={isOpenCreditForm}
                setIsOpenCreditForm={setIsOpenCreditForm}
                realBalance={realBalance}
                funBalance={funBalance}
                realCurrency={currentCurrency}
                funCurrencyConfig={funCurrencyConfig}
                asyncAction={handleAsyncAction}
            />

            <Button variant="outlined" color="inherit" onClick={handleClickOpenCreditForm} className={styles.creditFun}>
                Credit Fun: {formatBalance(funBalance, funCurrencyConfig)}
            </Button>
        </>
    )
}

export default Credit
