import { ConfiguredCurrencyData } from '../types/gameService'
import { ConfiguredCurrency, CurrencyConfig } from '../types/configuredCurrency'

export const parseCurrencyConfig = (currency: ConfiguredCurrencyData): ConfiguredCurrency => {
    try {
        const parsedConfig: CurrencyConfig = JSON.parse(currency.currencyConfig)
        return {
            ...currency,
            currencyConfig: parsedConfig,
        }
    } catch (error) {
        const errorMessage = `Failed to parse currencyConfig for ID ${currency.id}: ${error}`
        console.error(errorMessage)
        throw new Error(errorMessage)
    }
}

export const formatBalance = (balance: number, currencyConfig: CurrencyConfig): string => {
    const { precision, registerSeparator, decimalSeparator, useCents, isSymbolFirst, symbol } = currencyConfig

    const formattedBalance = useCents ? (balance / 10 ** precision).toFixed(precision) : Math.floor(balance).toString()
    const [integerPart, fractionalPart] = formattedBalance.split('.')
    const withSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, registerSeparator)

    const finalBalance = fractionalPart ? [withSeparators, fractionalPart].join(decimalSeparator) : withSeparators

    if (isSymbolFirst) {
        return `${symbol} ${finalBalance}`
    } else {
        return `${finalBalance} ${symbol}`
    }
}
