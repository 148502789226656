import React, { useEffect, useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import { BalanceMode } from 'types/common'
import AdministrationUserService from 'services/AdministrationUserService'
import UserService from 'services/UserService'
import CurrencyInput from 'react-currency-input-field'
import { CurrencyConfig, ConfiguredCurrency } from 'types/configuredCurrency'
import styles from './creditForm.module.scss'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../ErrorFallback/ErrorFallback'

interface CreditFormProps {
    userAPIType: 'RequestedUser' | 'AuthenticatedUser'
    isOpenCreditForm: boolean
    setIsOpenCreditForm: (value: boolean) => void
    realBalance: number
    funBalance: number
    userId?: number
    realCurrency: ConfiguredCurrency
    funCurrencyConfig: CurrencyConfig
    asyncAction: () => void
    sendMessageToIframe?: () => void
}

const CreditForm: React.FC<CreditFormProps> = ({
    userAPIType,
    isOpenCreditForm,
    setIsOpenCreditForm,
    realBalance,
    funBalance,
    userId,
    realCurrency,
    funCurrencyConfig,
    asyncAction,
    sendMessageToIframe,
}) => {
    const [openAlert, setOpenAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [severity, setSeverity] = useState<'success' | 'error'>('success')
    const [valueReal, setValueReal] = useState<string>('')
    const [valueFun, setValueFun] = useState<string>('')

    const convertBalanceToDisplayValue = (balance: number, useCents: boolean, precision: number) => {
        if (useCents) {
            return (balance / 10 ** precision).toFixed(precision)
        } else {
            return balance.toString()
        }
    }

    useEffect(() => {
        setValueReal(
            convertBalanceToDisplayValue(
                realBalance,
                realCurrency.currencyConfig.useCents,
                realCurrency.currencyConfig.precision
            )
        )
        setValueFun(convertBalanceToDisplayValue(funBalance, funCurrencyConfig.useCents, funCurrencyConfig.precision))
    }, [
        realBalance,
        funBalance,
        realCurrency.currencyConfig.useCents,
        realCurrency.currencyConfig.precision,
        funCurrencyConfig.useCents,
        funCurrencyConfig.precision,
    ])

    const handleCloseAlert = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        setOpenAlert(false)
    }

    const handleCloseDialog = () => setIsOpenCreditForm(false)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            const realMoneyValue = realCurrency.currencyConfig.useCents
                ? Math.round(parseFloat(valueReal.replace(',', '.')) * 10 ** realCurrency.currencyConfig.precision)
                : parseFloat(valueReal)

            const funMoneyValue = funCurrencyConfig.useCents
                ? Math.round(parseFloat(valueFun.replace(',', '.')) * 10 ** funCurrencyConfig.precision)
                : parseFloat(valueFun)

            const balance = [
                { mode: BalanceMode.REAL, value: realMoneyValue },
                { mode: BalanceMode.FUN, value: funMoneyValue },
            ]

            if (userAPIType === 'AuthenticatedUser') {
                await UserService.setBalanceAuthenticatedUser({ balance })
            } else if (userId) {
                await AdministrationUserService.setRequestedUserBalance({ id: userId, balance })
            }

            asyncAction()
            setSeverity('success')
            setResponseMessage('Credit successfully changed')
            handleCloseDialog()

            if (sendMessageToIframe) sendMessageToIframe()
        } catch (error) {
            const errorMessage = (error as Error).message
            setSeverity('error')
            setResponseMessage(errorMessage)
            console.error(errorMessage)
        } finally {
            setOpenAlert(true)
        }
    }

    return (
        <>
            <Dialog open={isOpenCreditForm} onClose={handleCloseDialog}>
                <DialogTitle className={styles.creditDialogTitle}>Change Credit</DialogTitle>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <DialogContent sx={{ paddingBottom: '10px' }}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.creditForm}>
                                <div className={styles.currencyInputWrapper}>
                                    <CurrencyInput
                                        className={styles.currencyInput}
                                        id="currency-input"
                                        name="real-credit"
                                        placeholder="Enter real credit amount"
                                        decimalScale={realCurrency.currencyConfig.precision}
                                        decimalsLimit={realCurrency.currencyConfig.precision}
                                        decimalSeparator={realCurrency.currencyConfig.decimalSeparator}
                                        groupSeparator={realCurrency.currencyConfig.registerSeparator}
                                        value={valueReal}
                                        onValueChange={(value) => setValueReal(value || '')}
                                    />
                                    <div className={styles.currencySymbol}>{realCurrency.currencyConfig.symbol}</div>
                                </div>
                                <div className={styles.currencyInputWrapper}>
                                    <CurrencyInput
                                        className={styles.currencyInput}
                                        id="currency-input"
                                        name="fun-credit"
                                        placeholder="Enter fun credit amount"
                                        decimalScale={funCurrencyConfig.precision}
                                        decimalsLimit={funCurrencyConfig.precision}
                                        decimalSeparator={funCurrencyConfig.decimalSeparator}
                                        groupSeparator={funCurrencyConfig.registerSeparator}
                                        value={valueFun}
                                        onValueChange={(value) => setValueFun(value || '')}
                                    />
                                    <div className={styles.currencySymbol}>{funCurrencyConfig.symbol}</div>
                                </div>
                                <DialogActions>
                                    <Button onClick={handleCloseDialog}>Cancel</Button>
                                    <Button type="submit">Change</Button>
                                </DialogActions>
                            </div>
                        </form>
                    </DialogContent>
                </ErrorBoundary>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
                    {responseMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreditForm
