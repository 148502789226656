import React, { useRef, useState } from 'react'
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import styles from './creditReal.module.scss'
import { formatBalance } from 'utils/currencyUtils'
import { ConfiguredCurrency } from 'types/configuredCurrency'

interface CreditRealProps {
    handleClickOpenCreditForm: () => void
    handleChangeCurrency: (currency: string) => void
    realBalance: number
    configuredCurrencies: ConfiguredCurrency[]
    currentCurrency: ConfiguredCurrency
}

const CreditReal: React.FC<CreditRealProps> = ({
    handleClickOpenCreditForm,
    handleChangeCurrency,
    realBalance,
    configuredCurrencies,
    currentCurrency,
}) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, currencyId: string): void => {
        handleChangeCurrency(currencyId)
        setOpen(false)
    }

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClick = (): void => {
        handleClickOpenCreditForm()
    }

    const handleClose = (event: Event): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    return (
        <>
            <ButtonGroup
                variant="outlined"
                color="inherit"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                <Button onClick={handleClick}>
                    <div className={styles.creditReal}>
                        Credit Real: {formatBalance(realBalance, currentCurrency.currencyConfig)}
                    </div>
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select game mode for reset"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {configuredCurrencies.map((currency) => (
                                        <MenuItem
                                            key={currency.id}
                                            onClick={(event) => handleMenuItemClick(event, currency.id)}
                                            selected={currentCurrency.id === currency.id}
                                        >
                                            {`${currency.id}`}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default CreditReal
