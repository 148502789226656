import { GameVersions } from '../types/gameService'

interface GameVersionDetails {
    name: string
    modified: string
    size: string
}

export const mapAndSortVersions = (versionsObj: GameVersions): GameVersionDetails[] => {
    return Object.entries(versionsObj)
        .map(([version, details]) => ({
            name: version,
            modified: details.modified,
            size: bytesToMB(Number(details.size)),
        }))
        .sort((a, b) => parseDate(b.modified).getTime() - parseDate(a.modified).getTime())
}

const bytesToMB = (bytes: number): string => (bytes / (1024 * 1024)).toFixed(2) + ' MB'

const parseDate = (dateString: string): Date => {
    const [day, month, yearTime] = dateString.split('.')
    const [year, time] = yearTime.split(' ')
    return new Date(`${year}-${month}-${day}T${time}`)
}
