import { useEffect, useState } from 'react'
import { Search } from './Search/Search'
import { FilterPanelContainer } from './FilterPanel/FilterPanelContainer'
import { GameSettingInterfaceContainer } from './GameSettingInterface/GameSettingInterfaceContainer'
import { Games } from './Games/Games'
import { useAppSelector } from 'hooks/useRedux'
import { EnvLanguage, Game, RunGameParameters } from 'types/gameService'
import {
    filterGamesByMathClassA,
    filterGamesByName,
    filterWorkingGamesByIntegrationBackend,
    sortGamesByClass,
    sortGamesByDeployDate,
} from 'utils/gameUtils'
import { CategorySort, GameSortOrder } from 'types/common'
import { LicensedSite } from 'types/backendService'
import MissettledTransactionFeatureContainer from 'components/MissettledTransactionFeature'
import styles from './main.module.scss'

interface MainProps {
    searchedGameName: string
    setSearchedGameName: (name: string) => void
    games: Game[]
    runGame: (gameParams: RunGameParameters) => void
    service: string
    isOnlyWorkingGames: boolean
    isOnlyClassesA: boolean
    sortOrder: GameSortOrder
    categorySort: CategorySort
    envLanguages: EnvLanguage[]
    licensedSites: LicensedSite[]
    isMisSettledTransactionAllowed: boolean
}

export const Main: React.FC<MainProps> = ({
    searchedGameName,
    setSearchedGameName,
    games,
    runGame,
    service,
    isOnlyWorkingGames,
    isOnlyClassesA,
    sortOrder,
    categorySort,
    envLanguages,
    licensedSites,
    isMisSettledTransactionAllowed,
}) => {
    const [filteredGames, setFilteredGames] = useState<Game[]>([])

    const { integrationBackend, selectedLicensedSite } = useAppSelector((state) => state.stateUI)

    useEffect(() => {
        let filteredGames = filterGamesByName(games, searchedGameName)

        if (isOnlyWorkingGames) {
            filteredGames = filterWorkingGamesByIntegrationBackend(
                filteredGames,
                integrationBackend,
                selectedLicensedSite
            )
        }

        if (categorySort === CategorySort.GameClass) {
            filteredGames = sortGamesByClass(filteredGames, sortOrder)
        } else {
            filteredGames = sortGamesByDeployDate(filteredGames, sortOrder)
        }

        if (isOnlyClassesA) {
            filteredGames = filterGamesByMathClassA(filteredGames)
        }

        setFilteredGames(filteredGames)
    }, [
        games,
        integrationBackend,
        isOnlyClassesA,
        isOnlyWorkingGames,
        searchedGameName,
        categorySort,
        sortOrder,
        selectedLicensedSite,
    ])

    return (
        <main className={styles.main}>
            <Search searchedGameName={searchedGameName} setSearchedGameName={setSearchedGameName} />
            <FilterPanelContainer envLanguages={envLanguages} licensedSites={licensedSites} />
            <div className={styles.switcherGroup}>
                <GameSettingInterfaceContainer />
                {isMisSettledTransactionAllowed && <MissettledTransactionFeatureContainer />}
            </div>
            <Games games={filteredGames} runGame={runGame} service={service} />
        </main>
    )
}
