import { ENVIRONMENT } from 'consts/constants'
import { IntegrationBackend } from 'types/common'
import {
    GamesInfoResponse,
    OpenGameResponse,
    LegacyBackendGameURLParams,
    OSIBackendOpenGameURLParams,
    EnvironmentLanguageInfoResponse,
    ConfiguredCurrenciesResponse,
} from 'types/gameService'
import { Status } from 'types/service'
import $api from 'utils/http'

export default class GameService {
    static async getLegacyGameURL(data: LegacyBackendGameURLParams): Promise<OpenGameResponse> {
        return $api.post(`${process.env.REACT_APP_API_URL}games/legacy/open`, data)
    }

    static async getOSIGameURL(data: OSIBackendOpenGameURLParams): Promise<OpenGameResponse> {
        return $api.post(`${process.env.REACT_APP_API_URL}games/osi/open`, data)
    }

    static async getGames(env = ENVIRONMENT.DEV): Promise<GamesInfoResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}games/info?`, {
            params: {
                env,
            },
        })
    }

    static async resetGameState(
        backend: IntegrationBackend,
        gameUid: string,
        env: string,
        gameMode: string,
        site: string
    ): Promise<Status> {
        return $api.put(`${process.env.REACT_APP_API_URL}games/${backend}/${gameUid}`, null, {
            params: {
                env,
                gameMode,
                site,
            },
        })
    }

    static async getLanguagesInfo(env = ENVIRONMENT.DEV): Promise<EnvironmentLanguageInfoResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}games/languages?`, {
            params: {
                env,
            },
        })
    }

    static async getConfiguredCurrencies(env: string): Promise<ConfiguredCurrenciesResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}games/currencies?`, {
            params: {
                env,
            },
        })
    }
}
