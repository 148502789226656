import { Tag } from './adminService'
import { BalanceMode } from './common'

export enum RoleTitle {
    Admin = 'admin',
    Developer = 'developer',
    Certificator = 'certificator',
    Demo = 'demo',
}

export enum PermissionTitle {
    DevGameAccess = 'dev_games_access',
    StageGameAccess = 'stage_games_access',
    CertGameAccess = 'cert_games_access',
    LiveGameAccess = 'live_games_access',
    EnvsPageAccess = 'envs_page_access',
    UserManagement = 'user_managment',
    MissettledTransaction = 'missettled_transaction_test',
}

export interface Permission {
    id: number
    title: PermissionTitle
    description: string
}
export interface RoleInfo {
    id: number
    title: string
    description: string
    permissions: Permission[]
}

export interface Balance {
    mode: BalanceMode
    value: number
}

export interface User {
    id: number
    nickname: string
    firstName: string
    lastName: string
    company: string
    email: string
    lastLogin: string
    role: RoleInfo
    balance: Balance[]
    tags: Tag[]
}
