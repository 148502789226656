import { Link } from 'react-router-dom'
import { AppBar, Container, IconButton, Toolbar } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { Permission, PermissionTitle } from 'types/user'
import { Page } from 'types/common'
import styles from './header.module.scss'
import { userHasPermission } from 'utils/userUtils'
import { ROUTE } from 'consts/constants'
import NavigationLinks from './links/NavigationLinks'
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup'
import { useState } from 'react'
import UserInfoContainer from './userInfo/UserInfoContainer'

interface HeaderProps {
    isAuth: boolean
    handleLogout: () => void
    currentPage: Page
    permissions?: Permission[]
}

const Header: React.FC<HeaderProps> = ({ isAuth, handleLogout, currentPage, permissions }) => {
    const isAllowAdminPage = permissions ? userHasPermission(permissions, PermissionTitle.UserManagement) : false
    const isEnvsPageAllowed = permissions ? userHasPermission(permissions, PermissionTitle.EnvsPageAccess) : false

    const [isMenuActive, setIsMenuActive] = useState(false)

    const toggleMenuActive = () => {
        setIsMenuActive(!isMenuActive)
    }

    return (
        <AppBar
            position="sticky"
            sx={{
                zIndex: 1,
            }}
        >
            <Toolbar>
                <Container
                    className={styles.header}
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            sm: 'space-between',
                        },
                        alignItems: 'center',
                    }}
                    maxWidth="xl"
                >
                    <Link to={ROUTE.MAIN}>
                        <img src="/img/logo-octavian-horizontal.svg" alt="Octavian games" className={styles.logo} />
                    </Link>
                    {currentPage !== Page.Login && (
                        <NavigationLinks
                            isEnvsPageAllowed={isEnvsPageAllowed}
                            isMenuActive={isMenuActive}
                            toggleMenuActive={toggleMenuActive}
                        />
                    )}
                    {isAuth ? (
                        <div className={styles.interface}>
                            {currentPage === Page.Main && <UserInfoContainer />}
                            <ButtonsGroup
                                isAllowAdminPage={isAllowAdminPage}
                                currentPage={currentPage}
                                handleLogout={handleLogout}
                                isMenuActive={isMenuActive}
                                toggleMenuActive={toggleMenuActive}
                            />
                        </div>
                    ) : (
                        <IconButton color="inherit" className={styles.iconBtn}>
                            <Link to={ROUTE.LOGIN}>
                                <AccountCircle
                                    sx={{ height: '2rem', width: '2rem', margin: '0.5rem', color: 'white' }}
                                />
                            </Link>
                        </IconButton>
                    )}
                </Container>
            </Toolbar>
        </AppBar>
    )
}

export default Header
